.ent-treat{
    height: 320px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    color: black;
}

.ent2{
    width: 32%;
    height: 100%;
    margin-left: 12px;
    border-radius: 10px;
    background-color:  #02ccfe;
}

.ent2 h1 {
    text-align: center;
    font-size: 25px;
    padding-top: 15px;
}

.ent2 p {
    font-size: 14px;
    padding-left: 14px;
    padding-top: 20px;
}




@media screen and (min-width: 300px) and (max-width: 600px){
    .ent-treat{
        height: 100%;
        width: 100%;
        margin-top: 0px;
        display: flex;
        color: black;
        flex-direction: column;
    }
    
    .ent2{
        width: 100%;
        height: 100%;
        margin-left: 0px;
        border-radius: 10px;
        background-color:  #02ccfe;
        margin-top: 12px;
    }
    
    .ent2 h1 {
        text-align: center;
        font-size: 25px;
        padding-top: 15px;
    }
    
    .ent2 p {
        font-size: 14px;
        padding-left: 14px;
        padding-top: 20px;
    }
    
}


@media screen and (min-width: 600px) and (max-width: 1250px){
    .ent-treat{
        height: 320px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        color: black;
    }
    
    .ent2{
        width: 32%;
        height: 100%;
        margin-left: 12px;
        border-radius: 10px;
        background-color:  #02ccfe;
    }
    
    .ent2 h1 {
        text-align: center;
        font-size: 25px;
        padding-top: 15px;
    }
    
    .ent2 p {
        font-size: 14px;
        padding-left: 14px;
        padding-top: 20px;
    }
    
}





.ent-treat2{
    height: 280px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    color: black;
}

.ent22{
    width: 32%;
    height: 100%;
    margin-left: 12px;
    border-radius: 10px;
    background-color:  #02ccfe;
}

.ent22 h1 {
    text-align: center;
    font-size: 25px;
    padding-top: 15px;
}

.ent22 p {
    font-size: 14px;
    padding-left: 14px;
    padding-top: 20px;
    
}




@media screen and (min-width: 300px) and (max-width: 600px){
    .ent-treat2{
        height: 100%;
        width: 100%;
        margin-top: 0px;
        display: flex;
        color: white;
        flex-direction: column;
    }
    
    .ent22{
        width: 100%;
        height: 100%;
        margin-left: 0px;
        border-radius: 10px;
        background-color:  #02ccfe;
        margin-top: 12px;
    }
    
    .ent22 h1 {
        text-align: center;
        font-size: 25px;
        padding-top: 15px;
    }
    
    .ent22 p {
        font-size: 14px;
        padding-left: 14px;
        padding-top: 20px;
    }
    
}


@media screen and (min-width: 600px) and (max-width: 1250px){
    .ent-treat2{
        height: 320px;
        width: 100%;
        margin-top: 20px;
        display: flex;
        color: white;
    }
    
    .ent22{
        width: 32%;
        height: 100%;
        margin-left: 12px;
        border-radius: 10px;
        background-color:  #02ccfe;
    }
    
    .ent22 h1 {
        text-align: center;
        font-size: 25px;
        padding-top: 15px;
    }
    
    .ent22 p {
        font-size: 14px;
        padding-left: 14px;
        padding-top: 20px;
    }
    
}