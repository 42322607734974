.navbar-brand,
.navbar-nav .nav-link {
  color: black;
  font-size: 17px;
  padding-left: 0px;


}

.navbar-nav .nav-link {
  margin-left: 20px;
  font-weight: bold;
}


.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;

}

.mains{
  width: 100%;
  height: 100%;
  background-color:lightyellow;
}







.carousal {
  width: 100%;
  height: 450px;
  background-color: lightgreen;
}


.carousal img {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  /* Add a smooth transition effect */
}





.context {
  width: 100%;
  height: 500px;
  margin-top: 100px;
  display: flex;

}

.context2 {
  width: 33%;
  height: 100%;
  margin-left: 50px;
  border-radius: 50px 20px;
}

.context2 img {
  width: 100%;
  height: 100%;
  border-radius: 50px 0px;
}


.context3 {
  width: 60%;
  height: 100%;
  margin-left: 40px;
}

.context3 h1 {
  font-size: 28px;
  padding-top: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.context3 h2 {
  font-size: 22px;
  padding-top: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.context3 h3 {
  font-size: 17px;
  padding-top: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}



.context3 p {
  padding-top: 05px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.context3 button {
  margin-left: 50px;
  margin-top: 30px;
  border: none;
  background-color: crimson;
  width: 140px;
  height: 40px;
  font-weight: bold;
  border-radius: 16px;
}


.footer {
  width: 100%;
  height: 500px;
  display: flex;
  margin-top: 80px;
}

.foot {
  width: 25%;
  height: 100%;

}

.foot h1 {
  text-align: center;
  font-size: 25px;
  padding-top: 20px;
  font-weight: bold;
  color: white;
  font-family: 'Times New Roman', Times, serif;
}

.foot p a {
  margin-left: 90px;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.foot p {
  margin-top: 40px;
  color: white;
  padding-left: 10px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
}

.disclamer {
  width: 100%;
  height: 80px;
  background-color: #232b2b;
}


.disclamer p {
  color: white;
  margin-left: 40px;
  padding-top: 20px;
  font-size: 20px;
}


.contain {
  max-width: 600px;
  margin: 50px auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h2 {
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

input {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}


@media screen and (min-width: 300px) and (max-width: 600px) {



  .mains{
    width: 100%;
    height: 100%;
  }
  .carousal {
    width: 100%;
    height: 190px;
    background-color: lightgreen;
  }


  .carousal img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    /* Add a smooth transition effect */
  }

  .context {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .context2 {
    width: 98%;
    height: 100%;
    margin-left: 03px;
    border-radius: 50px 20px;
  }

  .context2 img {
    width: 100%;
    height: 100%;
    border-radius: 50px 0px;
  }


  .context3 {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }

  .context3 h1 {
    font-size: 28px;
    margin-left: 10px;
    padding-top: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .context3 h2 {
    margin-left: 10px;
  }

  .context3 h3 {
    margin-left: 10px;
  }


  .context3 p {
    padding-top: 05px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 10px;
  }

  .context3 button {
    margin-left: 50px;
    margin-top: 10px;
    border: none;
    background-color: crimson;
    width: 140px;
    height: 30px;
    font-weight: bold;
  }

  .footer {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 80px;
    flex-direction: column;
  }

  .foot {
    width: 100%;
    height: 100%;

  }

  .foot h1 {
    text-align: center;
    font-size: 25px;
    padding-top: 20px;
    font-weight: bold;
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }

  .foot p a {
    margin-left: 120px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }

  .foot p {
    margin-top: 40px;
    color: white;
    padding-left: 15px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
  }

  .disclamer {
    width: 100%;
    height: 80px;
    background-color: #232b2b;
  }


  .disclamer p {
    color: white;
    margin-left: 20px;
    padding-top: 20px;
    font-size: 16px;
  }

}



@media screen and (min-width: 600px) and (max-width: 1250px) {
  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;
    font-size: 17px;
    padding-left: 0px;
  }
  
  .navbar-nav .nav-link {
    margin-left: 20px;
    font-weight: bold;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: red;
  
  }
  
  .mains{
    width: 1250px;
    height: 100%;
  }
  
  
  
  
  
  
  
  .carousal {
    width: 100%;
    height: 450px;
    background-color: lightgreen;
  }
  
  
  .carousal img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    /* Add a smooth transition effect */
  }
  
  
  
  
  
  .context {
    width: 100%;
    height: 500px;
    margin-top: 100px;
    display: flex;
  
  }
  
  .context2 {
    width: 33%;
    height: 100%;
    margin-left: 50px;
    border-radius: 50px 20px;
  }
  
  .context2 img {
    width: 100%;
    height: 100%;
    border-radius: 50px 0px;
  }
  
  
  .context3 {
    width: 60%;
    height: 100%;
    margin-left: 40px;
  }
  
  .context3 h1 {
    font-size: 28px;
    padding-top: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  
  .context3 h2 {
    font-size: 22px;
    padding-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  
  .context3 h3 {
    font-size: 17px;
    padding-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  
  
  .context3 p {
    padding-top: 05px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .context3 button {
    margin-left: 50px;
    margin-top: 30px;
    border: none;
    background-color: crimson;
    width: 140px;
    height: 40px;
    font-weight: bold;
    border-radius: 16px;
  }
  
  
  .footer {
    width: 100%;
    height: 500px;
    display: flex;
    margin-top: 80px;
  }
  
  .foot {
    width: 25%;
    height: 100%;
  
  }
  
  .foot h1 {
    text-align: center;
    font-size: 25px;
    padding-top: 20px;
    font-weight: bold;
    color: white;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .foot p a {
    margin-left: 90px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  .foot p {
    margin-top: 40px;
    color: white;
    padding-left: 10px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
  }
  
  .disclamer {
    width: 100%;
    height: 80px;
    background-color: #232b2b;
  }
  
  
  .disclamer p {
    color: white;
    margin-left: 40px;
    padding-top: 20px;
    font-size: 20px;
  }
  
}









.contact {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.contact2 {
  flex: 1;
  margin: 0 10px;
}

form {
  max-width: 400px;
  margin: auto;
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
